  .patient-scope {
    .react-autosuggest__container {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 6px;
        padding-left: 6px;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
      background-color: $white;
      border-radius: 5px;
      position: relative;
  
      .searchIcon {
        margin: 1px;
        stroke: $grey-darker !important;
        fill: $grey-darker !important;
      }
  
      .clearIcon {
        visibility: hidden;
        margin: 1px;
        stroke: $grey-darker !important;
        fill: $grey-darker !important;
        cursor: pointer;
      }
  
      &:hover {
        .clearIcon {
          visibility: visible;
        }
      }
    }
  
    .react-autosuggest__input {
      border: none;
      font-size: small;
  
      &::placeholder {
        color: $grey-dark;
      }
    }
  
    .react-autosuggest__input--focused {
      box-shadow: none;
    }
  
    .react-autosuggest__suggestions-container {
      background-color: $white;
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 100;
      // border: 1px solid $grey;
      overflow-y: auto;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  
      ul {
        list-style: none;
        padding: 0;
        max-height: 40vh;
        font-size: small;
      }
  
      .react-autosuggest__suggestion {
        padding: 1rem;
        cursor: default;
        transition: all 0.3s ease 0s;
      }
    }
  
    .react-autosuggest__suggestion-list--empty {
      padding: 1rem;
    }
  
    .react-autosuggest__suggestion--highlighted {
      background-color: $grey-lighter;
      
      &:hover {
        cursor: pointer;
      }
    }
  }
  
  .common-search-items {
    margin-top: 8vw;
  }
  