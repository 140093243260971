@use "sass:map";

// Step 1: Import Bootstrap dependencies
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Step 2: Override Bootstrap's variables
$primary: #0f73c2;
$grey: #718096;
$grey-light: #e2e8f0;
$grey-lighter: #f7fafc;
$grey-dark: #4a5568;
$grey-darker: #2d3748;
$secondary: $grey-darker;
$white: #ffffff;
$red: #fc0f2b;
$red-light: lighten($red, 15%);
$red-dark: darken($red, 30%);
$yellow: #fec829;
$green: #39b039;
$primary-light: lighten($primary, 20%);
$primary-lighter: lighten($primary-light, 10%);

// Merge your custom colors with Bootstrap's default $theme-colors
$custom-colors: (
  "primary": #0f73c2,
  "secondary": $secondary,
  "light-grey": $grey-light,
  "grey": $grey,
  "dark-grey": $grey-dark,
  "red-light": $red-light,
  "red-dark": $red-dark,
  "yellow": $yellow,
  "green": $green,
);

// Use map.merge to combine custom colors with Bootstrap's theme colors
$theme-colors: map.merge($theme-colors, $custom-colors);

// Step 3: Import Bootstrap's core styles
@import "~bootstrap/scss/bootstrap.scss";

// Step 4: Additional custom styles
@font-face {
  font-family: "Etelka";
  font-style: normal;
  font-weight: 400;
  src:
    local("EtelkaLight"),
    url(./../assets/fonts/EtelkaLight.otf) format("opentype");
}
@font-face {
  font-family: "Etelka";
  font-style: italic;
  font-weight: 400;
  src:
    local("EtelkaLightItalic"),
    url(./../assets/fonts/EtelkaLightItalic.otf) format("opentype");
}
@font-face {
  font-family: "Etelka";
  font-style: normal;
  font-weight: 600;
  src:
    local("EtelkaMedium"),
    url(./../assets/fonts/EtelkaMedium.otf) format("opentype");
}
@font-face {
  font-family: "Etelka";
  font-style: italic;
  font-weight: 600;
  src:
    local("EtelkaMediumItalic"),
    url(./../assets/fonts/EtelkaMediumItalic.otf) format("opentype");
}
