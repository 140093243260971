.sidebar {
  background-color: $white;
  border: 0 solid $grey-lighter;
  box-shadow: -8px 1px 20px 0 rgba(0, 0, 0, 0.10);
}

.navbar-collapse {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navbar img {
  height: 50px;
}

.sidebar {
  border-right-width: 1px;
  padding: 0;
}

.sidebar-sticky {
  position: sticky;
  top: 0;
  height: 100vh;
}

.sidebar-top {
  padding: 1rem;
}

.sidebar-bottom {
  height: 100%;
  overflow: auto;
}

.main-menu {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  padding: 0.5rem 0 0;

  & > ul {
    & > li {
      border-left: 5px solid transparent;
      transition: all 0.4s ease 0s;

      .label {
        display: block;
        padding: 1rem 1.5rem;
        color: $grey-darker;
        text-decoration: none;

        span {
          padding-left: 0.5rem;
          display: block;
        }
    
        svg {
          &:not(.stroke) * {
            fill: $grey-darker;
          }
    
          &.stroke {
            *:not(text) {
              stroke: $grey-darker;
            }
    
            text {
              fill: $grey-darker;
            }
          }
        }    
      }

      &.active {
        & > .label {
          font-weight: bold;
        }
      }

      &.active,
      &:hover {
        border-color: $primary;

        .label {
          color: $primary;
          text-decoration: none;
        }

        svg:not(.stroke) * {
          fill: $primary;
        }

        svg.stroke:not(text) * {
          stroke: $primary;
        }
      }
    }
  }

  .sub-nav {
    list-style: none;
    padding-left: 0;

    li {
      font-size: 85%;

      .label {
        transition: color 0.4s ease 0s;

        &:hover:not(.active) {
          color: $black;
        }

        span {
          padding-left: 0;
        }
      }

      &.active {
        .label {
          font-weight: bold;
        }
      }
    }
  }

  img,
  svg {
    width: 1.3rem;
    height: auto;
    margin-right: 1.5rem;
  }
}

.user-menu {
  border-bottom: 1px solid $grey-lighter;
  padding: 0.5rem 2rem;

  .user-info {
    p {
      margin: 0;
      padding-right: 1rem;
    }

    span {
      color: $grey-darker;
      font-size: 14px;
    }

    .username {
      font-weight: bold;
    }
  }

  .user-actions {
    padding-top: 0.3rem;

    a {
      text-align: center;
      border: 0;
      font-size: 0.8rem;
      color: $grey-darker;

      @media (max-width: 991.98px) {
        text-align: left;
      }

      &.active,
      &:hover {
        text-decoration: none;

        svg {
          stroke: $primary;
        }

        .label {
          color: $primary;
        }
      }

      .label {
        margin: 0.3rem 0;
      }

      .icon {
        width: 100%;
        margin: 0 auto;
        padding-left: 0.5rem;
      }

      svg {
        width: 20px;
        height: auto;
        stroke: $grey-darker;
      }
    }
  }
}

.toggler {
  cursor: pointer;
}

.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid $grey-lighter;
}

@media (max-width: 991.98px) {

  .sidebar-sticky {
    height: inherit;
    
    .sidebar-top {
      padding: 0;
  
      .logo {
        max-height: 60px;
      }
  
      .navbar-toggler {
        line-height: 60px;
        padding: 0;
      }
    }
  
    .main-menu {
      overflow: visible;
    }
  }

}
