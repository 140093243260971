.cookie-consent {
  align-items: baseline;
  background: rgba(45,55,72,.9);
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0;
  padding: 1.5rem 4rem 1rem;
  p {
    text-align: left;
  }
  .warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
}
